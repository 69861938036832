import { MenuGroupInfo, MenuItemInfo, RouteItem } from './Interface-MainFrame';
import { routes } from './RoutesConfig';
import { PermissionGroup } from '../../service/LoginEntity';
import { LS_Key } from '../../store/StorageKey';

let permission = JSON.stringify([{
    m_group_id: 1,
    m_group_name: "",
    m_permissions: { '200': "Home", '201': "Company", '202': "Produce", '203': "Contact", '204': "Privacy", }
}]);

localStorage.setItem(LS_Key.PERMISSION, permission);

/**
 * 根据登陆返回的【权限数据】生成主页面左侧的【菜单数据】
 * @param permission_data 
 */
export function get_permitted_menus(): Array<MenuGroupInfo> {
    let permission_str = localStorage.getItem(LS_Key.PERMISSION);

    try {
        let permission_group: Array<PermissionGroup> = JSON.parse(permission_str) as Array<PermissionGroup>;
        if (null == permission_group) {
            return [];
        }

        let menu_group: Array<MenuGroupInfo> = [];

        permission_group.forEach((group: PermissionGroup, index: number) => {

            let menu_items: Array<MenuItemInfo> = [];

            for (let key in group.m_permissions) {
                let permission_id = Number(key);
                let menu_name = group.m_permissions[key];
                let route = get_permitted_route(permission_id);

                if (null != route) {
                    if (204 == permission_id) continue;
                    menu_items.push({
                        route_path: route.route_path,
                        menu_name: menu_name
                    });
                }
            }

            if (menu_items.length <= 0) return;

            menu_group.push({
                group_id: group.m_group_id,
                group_name: group.m_group_name,
                menu_items: menu_items
            });
        });

        return menu_group;
    } catch (error) {
        console.log(error);
        return [];
    }
}

/**
 * 根据登陆返回的【权限数据】生成主页面的【路由数据】
 * @param permission_data 
 */
export function get_permitted_routes(): Array<RouteItem> {
    let permission_str = localStorage.getItem(LS_Key.PERMISSION);

    try {
        let permission_data: Array<PermissionGroup> = JSON.parse(permission_str) as Array<PermissionGroup>;
        if (null == permission_data) {
            return [];
        }

        let route_data: Array<RouteItem> = [];

        permission_data.forEach((v: PermissionGroup, index: number) => {

            for (let key in v.m_permissions) {
                let permission_id = Number(key);
                let item = get_permitted_route(permission_id);

                //console.log(`${[permission_id]}  =====> ${JSON.stringify(item)}`);

                if (null != item) {
                    route_data.push(item);
                }
            }
        });

        return route_data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

function get_permitted_route(permission_id: number): RouteItem {

    for (let i = 0; i < routes.length; i++) {
        if (routes[i].permission_id === permission_id) {
            return routes[i];
        }
    }

    return null;
}
