import React from 'react';
import "./MainFrame.css"
import { Layout } from 'antd';
import RouteList from './RouteList';
import MainMenu from './MainMenu';
import * as MenuRouteManger from './MenuRouteManger';

const { Header, Content, Footer } = Layout;

export default function MainFrame() {

  let routes = MenuRouteManger.get_permitted_routes();

  return (
    <Layout style={{ background: "#111" }}>
      <Header className="basicHeader">
        <div style={{ width: "19.20rem", margin: "auto" }}>
          <a href="index/home">沐萌科技</a>
          <MainMenu />
        </div>
      </Header>
      <Content className="basicContent">
        <div style={{ width: "19.20rem", margin: "auto" }}>
          <RouteList routes={routes}></RouteList>
        </div>
      </Content>
      <Footer className="basicFooter">
        <div style={{ width: "19.20rem", margin: "auto" }}>
          <code>Copyright &copy; 2021.Mumeng All rights reserved.    合作联系：<a href="mailto:Mumeng@gmail.com" style={{ textDecoration: "none" }}>
            Mumeng@Gmail.COM
          </a></code>&nbsp;<a href="/index/privacy" target="_block">隐私政策</a>
        </div>
        <div style={{ width: "19.20rem", margin: "auto" }}>
          <img width="20" height="20" className="beian" src={'https://beian.miit.gov.cn/favicon.ico'} ></img>
          <a href="https://beian.miit.gov.cn" target="_block" style={{ textDecoration: "none" }}>&nbsp;粤ICP备2020139219号</a>
        </div>
      </Footer>
    </Layout>
  );
}