import React from "react";
import { RouteItem } from "./Interface-MainFrame";

/**
 * 异步加载组件，避免首次打开页面loading太久 : https://react.docschina.org/docs/code-splitting.html
 * React.lazy 接受一个函数，这个函数需要动态调用 import()。
 * 它必须返回一个 Promise，该 Promise 需要 resolve 一个 defalut export 的 React 组件。
 */

const Home = React.lazy(() => import("../Home/Home"));
//const Produce = React.lazy(() => import("../Produce/Produce"));
const Contact = React.lazy(() => import("../Contact/Contact"));
const Company = React.lazy(() => import("../Company/Company"));
const Privacy = React.lazy(() => import("../Privacy/Privacy"));

//----------------------------------------------------------------------------------------------------------------------------
// MainFrame 的全局路由配置
export const routes: Array<RouteItem> = [
    {
        route_path: "/index/home",
        permission_id: 200,
        load_component: Home,
    },
    {
        route_path: "/index/company",
        permission_id: 201,
        load_component: Company,
    },
    // {
    //     route_path: "/index/produce",
    //     permission_id: 202,
    //     load_component: Produce,
    // },
    {
        route_path: "/index/contact",
        permission_id: 203,
        load_component: Contact,
    },
    {
        route_path: "/index/privacy",
        permission_id: 204,
        load_component: Privacy,
    },
];
