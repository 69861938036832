import React, { useEffect } from "react";
import { Menu } from "antd";
import { MenuGroupInfo, MenuItemInfo } from "./Interface-MainFrame";
import { ClickParam } from "antd/lib/menu";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import * as MenuRouteManger from './MenuRouteManger';

export default function MainMenu() {

    let menu_group = MenuRouteManger.get_permitted_menus();
    let subPageTitle = {};
    let group_items = menu_group.map((group: MenuGroupInfo) => {
        return group.menu_items.map((menu_item: MenuItemInfo) => {
            return (
                <Menu.Item key={menu_item.route_path} id={menu_item.menu_name}>
                    <FormattedMessage id={menu_item.menu_name} >
                        {(txt) => {
                            subPageTitle[menu_item.menu_name] = txt;
                            return txt;
                        }}
                    </FormattedMessage>
                </Menu.Item>
            );
        });
    });

    let history = useHistory();

    //菜单项点击的回调。 作用 : 刷新 BasicLayout 右侧区域的内容
    function handleClick(param: ClickParam) {
        let route_path = param.key;
        history.push(route_path);

        // document.title = subPageTitle[param.item.props.id];
    };

    let currKey = history.location.pathname;
    useEffect(() => {
        let cfg = menu_group[0].menu_items.find(v => v.route_path == currKey);
        if (cfg) {
            // document.title = subPageTitle[cfg.menu_name];
        }
    })
    
    return (
        <Menu
            mode="horizontal"
            theme="dark"
            className="nav-pc"
            onClick={handleClick}
            selectedKeys={[currKey]}
        >

            {group_items}
        </Menu>
    );
}