import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import MainFrame from './components/MainFrame/MainFrame';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import ant_ui_zh_CN from "antd/es/locale/zh_CN";
import data_zh_CN from "./locale/zh_CN.json";

import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const App: React.FC = () => {
    return (
        <ConfigProvider locale={ant_ui_zh_CN}>
            <IntlProvider locale="zh" messages={data_zh_CN}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/index" render={() => (<MainFrame />)} ></Route>
                        <Route path="/" render={() => (<Redirect to="/index/home" />)}></Route>
                    </Switch>
                </BrowserRouter >
            </IntlProvider>
        </ConfigProvider>
    )
}

export default App;