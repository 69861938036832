import { RouteListProps, RouteItem } from "./Interface-MainFrame";
import { Route, Switch } from "react-router";
import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";

export default function RouteList(props: RouteListProps) {
    const route_cfg = props.routes;
    let elements = route_cfg.map((item: RouteItem, index: number) => {
        return (<Route strict path={item.route_path} component={item.load_component} key={index}></Route>);
    });

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                {elements}
                <Route path="/" render={() => (<Redirect to="/index/home" />)}></Route>
            </Switch>
        </Suspense>
    );
}